<template>
  <div id="videos-tutoriales">
      <div class="row" id="videos">
        <div class="col-12 col-md-6 video-tutorial">
          <div class="font py-5">
            <h2>
              ¿Cómo ingresar al campus virtual?
            </h2>
          </div>
          <div class="embed-responsive embed-responsive-21by9 rounded-lg">
            <video src="@/assets/CARNET1.mp4" controls class="mx-2 rounded-lg"></video>

          </div>
        </div>
        <div class="col-12 col-md-6 video-tutorial ">
          <div class="font py-5">
            <h2>
              IPSSOBOT, nuestra Inteligencia Artificial
            </h2>
          </div>
          <div class="embed-responsive embed-responsive-21by9 rounded-lg">
            <video src="@/assets/BaileIpssoBot_Hor.webm" controls class="mx-2 rounded-lg"></video>

          </div>
        </div>
      </div>
    
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {},
};
</script>
<style>
#videos{
  padding: 50px;
  padding-top: 0;
}
</style>
