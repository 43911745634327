<template>
  <div class="font container-fluid" style="padding: 50px;">

    
    <h1 class="">Conoce más sobre IPSSOMA..</h1>
    <div class="row">
      <!-- <div class="embed-responsive embed-responsive-16by9 col-12 col-md-6 m-auto">
        <video src="@/assets/presentacion_vertical.mp4" controls class="mx-2"></video>
      </div> -->
      
      <div
        class="embed-responsive embed-responsive-21by9 col-12 col-md-6 rounded-lg my-6 "
      >
      
      <video src="@/assets/quienes_somos.mp4" controls class="mx-2 rounded-lg"></video>
      </div>
      <div class="col-md-6 col-12">
        <p class="lead text-justify">
          Somos una institución nacida en el 2018, con la modalidad E-learning,
          proponiendo una educación: rápida, flexible y de calidad, formando
          especialistas en el área de Seguridad y Salud en el trabajo, Medio
          Ambiente y Calidad, con una excelente plana docente y una malla
          curricular actualizada.<br />
          Desde nuestros inicios a la fecha, más de 5.000 alumnos han logrado
          capacitarse, certificarse y potenciar sus conocimientos con nuestros
          Cursos y Diplomados
        </p>
        <router-link to="/historia" class="btn btn-warning btn-lg offset-sm-6 offset-md-9 mt-16 text-dark" style="padding: 10px 20px; border-radius: 10px;">
          Conocer más
        </router-link>
        <!-- <video src="@/assets/presentacion_horizontal.mp4" controls class="mx-2"></video> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {},
};
</script>

<style>
.font{
  font-family: 'Segoe UI'!important;
}
</style>