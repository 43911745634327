<template>
    <div class="productModal">
        <div class="productModal-inner">
            <div class="productModal-close">
                &times;
            </div>
            <h3><img
                    src="../../../assets/box-arrow-up-right.svg"
                    alt=""
                    id="goToCourse-icon"
                    style="width: 30px;"
                  /></h3>
            <div class="embed-responsive embed-responsive-21by9">
                <video src="../../../assets/CARNET1.mp4" controls></video>
            </div>
            <p>
                a
            </p>
            <div class="productModal-extra">
                <span class="text-left text-secondary"
                  ><img
                    src="../../../assets/minicertificate.svg"
                    alt=""
                    style="width: 30px;"
                  />Incluye certificado</span
                >
                <span class="text-left text-secondary"
                  ><img src="../../../assets/minicertificate.svg" alt="" style="width: 30px;"/>100%
                  virtual</span
                >
            </div>
            <button type="button" class="btn btn-warning" id="productModal-btn">Comprar ahora</button>
        </div>
    </div>
</template>
<script>
    export default{
        name: "ShoppingCart",
        data(){
            return{
            }
        },
        methods:{
            closeShoppingCart(){
                this.$emit('closeShoppingCart')
            },
        }
    }
</script>
<style>
    .productModal{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        display: grid;
        place-items: center;
    }
    .productModal-close{
        position: absolute;
        height: 50px;
        aspect-ratio: 1/1;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        right: 0;
        font-size: 3rem;
        color: #ffc107;
        cursor: pointer;
    }
    .productModal-inner{
        background-color: #fff;
        color: #000;
        position: relative;
        width: 50%; 
        border-radius: 15px;
    }
    .productModal-inner h3{
        margin: 2.5%;
    }
    .productModal-inner p{
        margin: 2.5%;
    }
    .productModal-inner img{
        width: 100%;
        aspect-ratio: 16/9;
    }
    .productModal-extra{
        margin: 2.5%;
    }
    #productModal-btn{
        border-radius: 15px;
        padding: 10px 50px;
        display: flex;
        float: right;
        margin: 0px 20px 20px 0px;
    }
    #goToCourse-icon{
        cursor: pointer;
    }
</style>