<template>
  <div class="container-fluid" id="nosotros">
    <div class="row justify-content-center">
      <div class="col-sm-12 col-lg-12" style="background-color: #343a40">
        <h2 style="font-size: 1.9em; color: #fff" class="mt-5 text-center py-5">
          Nuestros docentes especializados
        </h2>
        <carousel :autoplay="true" :loop="true" :autoplayLoop="true" :autoplayTimeout="3000" :autoplayHoverPause="true"
          :navigationEnabled="true" :paginationEnabled="false" :perPageCustom="[
          [0, 1],
          [450, 1],
          [680, 2],
          [940, 2],
          [1100, 3],
          [1200, 4],
        ]" class="carousel-centered" id="carousel-teachers">

          <slide style="display: flex; justify-content: center;">
            <div class="card"
              style="width: 18rem; border: #343a40; border-radius: 25px; display: flex; justify-content: center;">
              <img src="../../../assets/L.png" class="card-img-top" alt="..." />
              <div class="card-body" style="
                  background-color: #131b1e;
                  border-radius: 0px 0px 25px 25px;
                ">
                <h5 class="card-title text-white">Luis Cruzado</h5>
                <p class="card-text text-secondary">Ingeniero</p>
                <div class="text-end">
                  <button @click="openModal('Luis Cruzados', require('@/assets/L1.png'), getInfoModal1,'Ingeniero')" type="button"
                    class="text-warning" data-toggle="modal" data-target="#modalstatico">
                    Ver cv <img src="../../../assets/fluentright.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </slide>

          <slide style="display: flex; justify-content: center;">
            <div class="card" style="width: 18rem; border: #343a40; border-radius: 25px">
              <img src="../../../assets/J.png" class="card-img-top" alt="..." />
              <div class="card-body" style="
                  background-color: #131b1e;
                  border-radius: 0px 0px 25px 25px;
                ">
                <h5 class="card-title text-white">Jorge Arzapalo</h5>
                <p class="card-text text-secondary">Ingeniero</p>
                <div class="text-end">
                  <button @click="openModal('Jorge Arzapalo', require('@/assets/J1.png'), getInfoModal2,'Ingeniero')" type="button"
                    class="text-warning" data-toggle="modal" data-target="#modalstatico">
                    Ver cv <img src="../../../assets/fluentright.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </slide>
          <slide style="display: flex; justify-content: center;">
            <div class="card" style="width: 18rem; border: #343a40; border-radius: 25px">
              <img src="../../../assets/E.png" class="card-img-top" alt="..." />
              <div class="card-body" style="
                  background-color: #131b1e;
                  border-radius: 0px 0px 25px 25px;
                ">
                <h5 class="card-title text-white">Emilio López Ríos</h5>
                <p class="card-text text-secondary">Ingeniero</p>
                <div class="text-end">
                  <button @click="openModal('Emilio López Ríos', require('@/assets/E1.png'), getInfoModal3,'Ingeniero')"
                    type="button" class="text-warning" data-toggle="modal" data-target="#modalstatico">
                    Ver cv <img src="../../../assets/fluentright.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </slide>
          <slide style="display: flex; justify-content: center;">
            <div class="card" style="width: 18rem; border: #343a40; border-radius: 25px;">
              <img src="../../../assets/G.png" class="card-img-top" alt="..." />
              <div class="card-body" style="
                  background-color: #131b1e;
                  border-radius: 0px 0px 25px 25px;
                ">
                <h5 class="card-title text-white">Cristina Prieto Mena</h5>
                <p class="card-text text-secondary">Ingeniera</p>
                <div class="text-end">
                  <button @click="openModal('Cristina Prieto Mena', require('@/assets/G1.png'), getInfoModal4,'Ingeniera')"
                    type="button" class="text-warning" data-toggle="modal" data-target="#modalstatico">
                    Ver cv <img src="../../../assets/fluentright.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </slide>
        </carousel>
        <div class="modal fade " id="modalstatico" data-backdrop="static" data-keyboard="false" tabindex="-1"
          aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg ">
            <div class="modal-content">
              <div class="modal-header">
                <div class="row">
                  <div class="col-md mt-4">
                    <img class="border rounded-lg p-6" :src="modalImage" alt="imagencard" style="
                        width: 100%;
                        object-fit: cover;
                        background-color: #f0f0f0;
                      " />
                  </div>
                  <div class="modulo col-md-8">
                    <div class="col-12">
                      <h2 class="modal-title" id="staticBackdropLabel">
                        {{ modalTitle }} <br />
                        <h6 class="text-secondary">{{modalPosition}}</h6>
                      </h2>
                      <p>
                        {{ modalInfo }}
                      </p>
                    </div>
                  </div>
                  <div id="btn_close_modal">
                    <button type="button" class="close text-warning" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>


    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      modalImage: '',
      modalTitle: '',
      modalInfo: '',
      modalPosition:'',

    };
  },

  methods: {
    openModal(title, image, getInfoFunction,position) {
      this.modalTitle = title;
      this.modalImage = image;
      this.modalPosition=position;
      this.modalInfo = getInfoFunction();
    },
    getInfoModal1() {

      return "El Ing. Luis Antonio Cruzado Bartra es un asociado al Instituto de Ingeniero de Minas del Perú (IIMP) con más de 10 años de experiencia en supervisión y capacitación en trabajos de alto riesgo,abordando temas como seguridad, salud ocupacional y medio ambiente. Además, es un destacado ponente en eventos académicos y seminarios a nivel nacional.";
    },
    getInfoModal2() {

      return "El Ing. Jorge Luis Arzapalo Barrera es un ingeniero graduado de la UNI y posee un máster en Innovación Pedagógica. Como Gerente General de RUPAC E.I.R.L., cuenta con una amplia experiencia en áreas técnicas, administrativas y gestión de proyectos de construcción. Además, se interesa por la formación continua, la inteligencia emocional y la gestión de equipos.";
    },
    getInfoModal3() {

      return "El Ing. Medardo Emilio López Ríos es un profesional en Ingeniería Industrial con amplia experiencia en prevención de riesgos laborales y sistemas integrados de gestión. Ha trabajado en diversos sectores, incluyendo generación eléctrica, construcción, metrología e industrial, destacando por su conocimiento en seguridad y salud ocupacional.";
    },
    getInfoModal4() {

      return "La Ing. Cristina Prieto Mena es una joven profesional en Ingeniería Industrial con un sólido deseo de superación y emprendimiento. Con más de 5 años de experiencia en la gestión de almacenes y proyectos logísticos, su expertise abarca diversos sectores, demostrando habilidades en la implementación y optimización de procesos en operaciones de gran envergadura.";
    },
  },
};
</script>

<style>
.modulo p{
  text-align: justify ;
}
.img-not-selectable {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  max-height: 300px;
  height: 300px;
  min-height: 300px;
}
#carousel-teachers .VueCarousel-wrapper{
  width: 100%;
  padding-left: 2%;
}
#carousel-teachers{
  width: 100%;
  margin: 50px 0;
}
.modal-content{
  padding: 20px; 
  position: relative;
  border-radius: 12px;
}
#btn_close_modal{
  position: absolute; 
  right: 5%;
}
#btn_close_modal button{
  font-size: 50px;
  font-weight: 300;
}
</style>
