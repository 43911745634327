<template>
  <div>
    <!-- Crear contador para los simposios -->
    <CountDownAlert />
    <ShoppingCart v-if="isCartOpen" :openShoppingCart="toggleCart()" ></ShoppingCart>
    <AnnouncementSlider  class="bg-image-size"></AnnouncementSlider>
    <ProductsSlider
      :products="all_products"
      :title="title"
      class="m-5"
      id="graduates"
    />
    <AboutUs class="my-5"></AboutUs>
    <AboutVideo></AboutVideo>
    <!-- <Iso></Iso> -->
    <Tutorials></Tutorials>
    <Info></Info>
    <Carnets></Carnets>
    <Checker></Checker>
  </div>
</template>
<script>
import CountDownAlert from "@/components/public/CountDownAlert/index.vue";
import ShoppingCart from "@/components/public/ShoppingCart/index.vue";
import AnnouncementSlider from "@/components/public/AnnouncementSlider/index.vue";
import AboutUs from "@/components/public/AboutUs/index.vue";
import AboutVideo from "@/components/public/AboutVideo/index.vue";
// import Separator from "@/components/public/Separator/index.vue";
import ProductsSlider from "@/components/public/ProductsSlider/index.vue";
// import Iso from "@/components/public/Iso/index.vue";
import Tutorials from "@/components/public/Tutorials/index.vue";
import Info from "@/components/public/Info/index.vue";
import Checker from "@/components/public/Checker/index.vue";
import Carnets from "@/components/public/Carnets/index.vue";
export default {
  data() {
    return {
      all_products: [],
      alert: true,
      title: "Aprende con nosotros",
      mode: "public",
      showPrivateNavbar: true,
      loading: true,
      isCartOpen: false
    };
  },
  methods: {
    async getProducts() {
      const { data } = await this.$axios.get(`/public/course/list`);
      this.all_products = data;
    },
    toggleCart(){
      this.isCartOpen = !this.isCartOpen
    }
  },
  mounted() {
    this.getProducts();
  },

  components: {
    ShoppingCart,
    CountDownAlert,
    AnnouncementSlider,
    AboutVideo,
    AboutUs,
    ProductsSlider,
    // Iso,
    Tutorials,
    Info,
    Checker,
    Carnets,
  },

  created() {
    localStorage.setItem("mode", "public");
    localStorage.setItem("showPrivateNavbar", false);
    localStorage.setItem("showPublicNavbar", true);
    localStorage.setItem("showPublicFooter", true);
    // this.getProducts();
  },
};
</script>
<style>
body {
  background-color:white;

}
.bg-image-size * {
  background-size: 100% 100%;
}

/* a {
  background-color: #fff !important;
} */
</style>
